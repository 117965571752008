import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,_vm._l((_vm.groups),function(group,i){return _c(VRow,{key:i},[_c(VCol,{attrs:{"data-group":_vm.getGroupId(group)}},[(!_vm.noHeader)?[_c('div',{staticClass:"text-capitalize d-flex align-center primary--text"},[_c(VIcon,{attrs:{"small":"","left":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.entityIcon)+" ")]),_vm._v(" "+_vm._s(_vm.getGroupLabel(group))+" ")],1),_c(VDivider,{staticClass:"mt-2 mb-4"})]:_vm._e(),_vm._l((group),function(box){return _c('a-text-input',{key:box.id,attrs:{"vid":`box_${box.id}`,"label":box.description,"rules":`required|max:${box.video_text_box.max_length}`,"readonly":_vm.readonly,"hint":_vm.getBoxHint(box),"rows":"1","auto-grow":"","textarea":""},on:{"focus":function($event){return _vm.activateSlide(box)}},model:{value:(box.video_text_box.video_caption.text),callback:function ($$v) {_vm.$set(box.video_text_box.video_caption, "text", $$v)},expression:"box.video_text_box.video_caption.text"}})})],2)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }