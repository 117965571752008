<template>
    <affix
        ref="affix"
        v-resize.quiet="onResizeDebounced"
        v-bind="$attrs"
        class="sticky-module"
    >
        <slot></slot>
    </affix>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { Affix } from 'vue-affix';
import { debounce } from '@/utils/helpers';

@Component({
    inheritAttrs: false,
    components: {
        Affix
    }
})
export default class StickyMedia extends Vue {
    $refs!: {
        affix: Affix;
    };

    sticky: { width: null | number; height: null | number } = {
        width: null,
        height: null
    };

    mounted() {
        this.initStickyModule();
    }

    onResize() {
        // clean the sticky container styles - unstick it
        this.$refs.affix?.removeClasses();
        // get new dims
        this.initStickyModule();
        // let Affix to reinit
        this.$refs.affix?.updateData();
    }

    onResizeDebounced = debounce(this.onResize, 250);

    initStickyModule() {
        this.sticky = this.getContainerDims();

        this.setVars(this.sticky.height, this.sticky.width);
    }

    setVars(height: number | null, width: number | null) {
        const element = this.$refs.affix?.$el as HTMLDivElement;

        if (element) {
            element.style.setProperty(
                '--sticky-module-height',
                height !== null ? `${height}px` : null
            );

            element.style.setProperty(
                '--sticky-module-width',
                width !== null ? `${width}px` : null
            );
        }
    }

    getContainerDims() {
        if (this.$slots.default) {
            const container = this.$slots.default[0];

            if (container.elm) {
                return (
                    container.elm as HTMLDivElement
                ).getBoundingClientRect();
            }
        }

        return {
            width: null,
            height: null
        };
    }
}
</script>

<style lang="scss">
.sticky-module.affix {
    width: var(--sticky-module-width);
    z-index: 1;

    + * {
        padding-top: var(--sticky-module-height);
    }
}
</style>
