import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import Resize from 'vuetify/lib/directives/resize';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('validation-provider',{ref:"provider",attrs:{"slim":"","detect-input":false,"rules":_vm.rules,"name":_vm.fieldName,"vid":_vm.$attrs.vid}},[_c(VContainer,{directives:[{def: Resize,name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],staticClass:"pa-0"},[_c(VCarousel,{ref:"container",staticClass:"amp-video-preview",class:{ zoom: _vm.isFullScreen, light: _vm.useLightTheme },attrs:{"show-arrows-on-hover":"","prev-icon":"angle-left","next-icon":"angle-right","delimiter-icon":_vm.delimiterIcon,"continuous":false,"hide-delimiters":_vm.isLoading},model:{value:(_vm.slide),callback:function ($$v) {_vm.slide=$$v},expression:"slide"}},[(!_vm.isLoading)?_c('a-box-actions',{attrs:{"options":_vm.actionOptions,"readonly":_vm.readonly},on:{"change":_vm.change,"media-deleted":_vm.onMediaDeleted}}):_vm._e(),(_vm.isLoading)?_c(VCarouselItem,{key:1},[_c('div',{staticClass:"slide-container",style:(_vm.slideStyle)},[(_vm.isLoading)?_c(VProgressCircular,{staticClass:"progress-indicator",attrs:{"indeterminate":"","color":"white","size":"350","width":"30"}}):_vm._e()],1)]):_vm._l((_vm.totalSlides),function(slideNumber){return [(_vm.slideAcceptsUserInput(slideNumber - 1))?_c(VCarouselItem,{key:slideNumber,attrs:{"value":slideNumber}},[_c('a-slide-actions',{attrs:{"boxes":_vm.getVisibleBoxesForSlide(slideNumber - 1),"readonly":_vm.readonly},on:{"change":_vm.change,"toggle-zoom":_vm.toggleZoom,"toggle-theme":_vm.toggleTheme}}),_c('div',{staticClass:"slide-container",style:(_vm.slideStyle)},[_c('a-zoom-box',{attrs:{"box":_vm.getZoomBoxForSlide(slideNumber - 1)}},_vm._l((_vm.getVisibleBoxesForSlide(
                                    slideNumber - 1
                                )),function(box,i){return _c('a-video-box',{key:box.id,attrs:{"box":box,"index":i,"readonly":_vm.readonly},on:{"change":_vm.change,"action":_vm.onBoxAction}})}),1)],1)],1):_vm._e()]})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }