<template>
    <span v-if="isCroppable">
        <a-crop-media-activator @crop="crop" />
        <a-crop-media-handler ref="handler" :box="box" v-on="$listeners" />
    </span>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import ACropMediaActivator from '@/components/ASlideshow/SlideActions/ACropMediaActivator.vue';
import ACropMediaHandler from '@/components/ASlideshow/SlideActions/ACropMediaHandler.vue';

import type { VideoBox } from '@/types/Video';

const ACropMediaProps = Vue.extend({
    name: 'ACropMedia',
    props: {
        box: {
            type: Object as PropType<VideoBox>,
            required: true
        }
    }
});

@Component({
    components: {
        ACropMediaActivator,
        ACropMediaHandler
    }
})
export default class ACropMedia extends ACropMediaProps {
    $refs!: {
        handler: InstanceType<typeof ACropMediaHandler>;
    };

    get resource() {
        return this.box?.video_media_box?.media_resource;
    }

    get isCroppable() {
        return this.resource?.media_file?.type === 0 && this.fileSrc;
    }

    get fileSrc() {
        return this.resource?.media_file?.relative_filename || '';
    }

    crop() {
        this.$refs.handler.crop();
    }
}
</script>
