<template>
    <div class="text-right pa-1 actions">
        <a-theme-toggle v-on="$listeners" />
        <a-full-screen v-on="$listeners" />
        <a-replace-media v-if="isReplaceable" :box="box" v-on="$listeners" />
        <a-crop-media v-if="actionable" :box="box" v-on="$listeners" />
    </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component, { mixins } from 'vue-class-component';

import { MediaModuleMedia } from '@/mixins';

import AReplaceMedia from './AReplaceMedia.vue';
import AThemeToggle from './AThemeToggle.vue';
import ACropMedia from './ACropMedia.vue';
import AFullScreen from './AFullScreen.vue';

import type { VideoBox } from '@/types/Video';

const ASlideActionsProps = Vue.extend({
    name: 'ASlideActions',
    props: {
        boxes: {
            type: Array as PropType<VideoBox[]>,
            required: true
        },
        readonly: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        AReplaceMedia,
        AThemeToggle,
        ACropMedia,
        AFullScreen
    }
})
export default class ASlideActions extends mixins(
    ASlideActionsProps,
    MediaModuleMedia
) {
    get mediaBoxes() {
        return this.boxes.filter(
            box => box.type === 'video_media_box' && this.mediaIsChangeable(box)
        );
    }

    get box() {
        // slide media actions for single box only
        if (this.mediaBoxes.length === 1) {
            return this.mediaBoxes[0];
        }

        return void 0;
    }

    get actionable() {
        return Boolean(!this.readonly && this.box);
    }

    get isReplaceable() {
        return Boolean(
            this.actionable && !this.box?.video_media_box?.is_logo_box
        );
    }
}
</script>

<style lang="scss" scoped>
.actions {
    background-color: rgba(map-get($shades, 'black'), 0.3);
    position: relative;
    z-index: 1; // appear on top of the slide
}
</style>
