<template>
    <v-container>
        <v-row v-for="(group, i) in groups" :key="i">
            <v-col :data-group="getGroupId(group)">
                <template v-if="!noHeader">
                    <div
                        class="text-capitalize d-flex align-center primary--text"
                    >
                        <v-icon small left color="primary">
                            {{ entityIcon }}
                        </v-icon>
                        {{ getGroupLabel(group) }}
                    </div>
                    <v-divider class="mt-2 mb-4" />
                </template>
                <a-text-input
                    v-for="box in group"
                    :key="box.id"
                    v-model="box.video_text_box.video_caption.text"
                    :vid="`box_${box.id}`"
                    :label="box.description"
                    :rules="`required|max:${box.video_text_box.max_length}`"
                    :readonly="readonly"
                    :hint="getBoxHint(box)"
                    rows="1"
                    auto-grow
                    textarea
                    @focus="activateSlide(box)"
                />
            </v-col>
        </v-row>
    </v-container>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { ATextInput } from '@/components/AForm/Inputs/ATextInput';

import type { VideoBox, VideoTemplate, VideoTextBox } from '@/types/Video';

type TextBox = VideoBox & {
    video_text_box: VideoTextBox;
};

const MediaFormProps = Vue.extend({
    name: 'MediaForm',
    props: {
        template: {
            type: Object as PropType<VideoTemplate>,
            default() {
                return {
                    video_boxes: []
                };
            }
        },
        hasLogo: {
            type: Boolean,
            default() {
                return false;
            }
        },
        readonly: {
            type: Boolean,
            default() {
                return false;
            }
        },
        noHeader: {
            type: Boolean,
            default() {
                return false;
            }
        },
        entity: {
            type: String,
            default() {
                return 'slide';
            }
        },
        entityIcon: {
            type: String,
            default() {
                return 'circle';
            }
        }
    }
});

@Component({
    components: {
        ATextInput
    }
})
export default class MediaForm extends MediaFormProps {
    get textBoxes() {
        const boxes: TextBox[] = [];

        this.template.video_boxes.forEach(box => {
            if (this.isVideoTextBox(box)) {
                boxes.push(box);
            }
        });

        return boxes;
    }

    get groups() {
        const groups: TextBox[][] = [];

        this.textBoxes.forEach(box => {
            if (!groups[box.ui_group]) {
                groups[box.ui_group] = [box];
            } else {
                groups[box.ui_group].push(box);
            }
        });

        return groups.filter(Boolean);
    }

    getGroupLabel(group: TextBox[]) {
        return group.length ? `${this.entity} ${group[0].ui_group + 1}` : '';
    }

    getGroupId(group: TextBox[]) {
        return group.length ? group[0].ui_group + 1 : 0;
    }

    activateSlide(box: TextBox) {
        this.$emit('activate', box.ui_group + 1);
    }
    // type predicate
    isVideoTextBox(box: VideoBox | TextBox): box is TextBox {
        return box.type === 'video_text_box';
    }

    getBoxHint(box: VideoBox) {
        return box.video_text_box?.example_text || '';
    }

    scrollToGroup(id: number) {
        const group = this.$el.querySelector(`[data-group="${id}"]`);

        if (group) {
            this.$vuetify.goTo(group as HTMLElement);
        }
    }
}
</script>
