<template>
    <div class="zoom-box" :style="getStyleForZoomBox()"><slot></slot></div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component, { mixins } from 'vue-class-component';

import { MediaBox } from '@/mixins';

import type { VideoBox } from '@/types/Video';

const AZoomBoxProps = Vue.extend({
    name: 'AZoomBox',
    props: {
        box: {
            type: Object as PropType<VideoBox>,
            default(): VideoBox | null {
                return null;
            }
        }
    }
});

@Component
export default class AZoomBox extends mixins(AZoomBoxProps, MediaBox) {
    getStyleForZoomBox() {
        if (this.box) {
            if (this.box.type === 'video_zoom_box' && this.box.video_zoom_box) {
                const originTop =
                    (this.box.y / 1080) *
                    (100 * this.box.video_zoom_box.zoom_factor);

                const originLeft =
                    (this.box.x / 1920) *
                    (100 * this.box.video_zoom_box.zoom_factor);

                return {
                    ...this.getStyleForBox(this.box),
                    top: 'initial',
                    left: 'initial',
                    transform: `scale(${this.box.video_zoom_box.zoom_factor})`,
                    transformOrigin: `${originLeft < 100 ? originLeft : 100}% ${
                        originTop < 100 ? originTop : 100
                    }%`
                };
            }
        }

        return '';
    }
}
</script>

<style lang="scss">
.zoom-box {
    transition: 0.5s ease-in-out;
    transform-origin: center;
    position: absolute;
}
</style>
